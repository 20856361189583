import React, { useRef, useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import Leftbar from './components/Leftbar';
import Rightbar from './components/Rightbar';
import NoProfile from './images/no-profile.jpg';
import Video1 from './videos/video1.mp4';
import Video2 from './videos/video2.mp4';
import Video4 from './videos/video3.mp4';
import { Link } from 'react-router-dom';
import MobileMenue from './components/MobileMenue';

// Sample video data array
const videoData = [
  {
    src: Video1,
    username: 'User1',
    realName: 'Real Name 1',
    videoTitle: 'Video Title 1',
    originalSound: 'Original Sound 1',
    likes: 48,
    comments: 12,
    profileImage: NoProfile,
  },
  {
    src: Video4,
    username: 'User2',
    realName: 'Real Name 2',
    videoTitle: 'Video Title 2',
    originalSound: 'Original Sound 2',
    likes: 30,
    comments: 8,
    profileImage: NoProfile,
  },
  {
    src: Video2,
    username: 'User3',
    realName: 'Real Name 3',
    videoTitle: 'Video Title 3',
    originalSound: 'Original Sound 3',
    likes: 72,
    comments: 22,
    profileImage: NoProfile,
  },
];

const Following = () => {
  const videoContainerRef = useRef(null);
  const [videoStates, setVideoStates] = useState(
    videoData.map(() => ({ playing: false, muted: false }))
  );
  

  const handlePlayPause = (index) => {
    const videoElement = videoContainerRef.current.querySelectorAll('video')[index];
    const isPlaying = !videoElement.paused;

    if (isPlaying) {
      videoElement.pause();
    } else {
      videoElement.play();
    }

    setVideoStates((prevStates) =>
      prevStates.map((state, idx) =>
        idx === index ? { ...state, playing: !isPlaying } : state
      )
    );
  };

  const handleMuteUnmute = (index) => {
    const videoElement = videoContainerRef.current.querySelectorAll('video')[index];
    const isMuted = videoElement.muted;

    videoElement.muted = !isMuted;

    setVideoStates((prevStates) =>
      prevStates.map((state, idx) =>
        idx === index ? { ...state, muted: !isMuted } : state
      )
    );
  };

  // Function to handle button scroll and play video
  const handleScroll = (direction) => {
    if (!videoContainerRef.current) return;

    const container = videoContainerRef.current;
    const scrollAmount = container.clientHeight; // Scroll by the height of the visible container

    // Determine the current scroll position
    const currentScroll = container.scrollTop;

    // Calculate the new scroll position
    let newScrollPosition;
    if (direction === 'up') {
      newScrollPosition = currentScroll - scrollAmount;
    } else if (direction === 'down') {
      newScrollPosition = currentScroll + scrollAmount;
    }

    // Perform the scroll with smooth behavior
    container.scrollTo({
      top: newScrollPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const container = videoContainerRef.current;

    const handleScrollEvent = () => {
      if (!container) return;

      const videos = container.querySelectorAll('video');
      videos.forEach((video, index) => {
        const rect = video.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          video.play();
          setVideoStates((prevStates) =>
            prevStates.map((state, idx) =>
              idx === index ? { ...state, playing: true } : state
            )
          );
        } else {
          video.pause();
          setVideoStates((prevStates) =>
            prevStates.map((state, idx) =>
              idx === index ? { ...state, playing: false } : state
            )
          );
        }
      });
    };

    // Attach scroll event listener
    container.addEventListener('scroll', handleScrollEvent);

    // Check visibility of videos initially
    handleScrollEvent();

    // Cleanup function to remove event listener
    return () => {
      container.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <div className="bg-gray-50 dark:bg-gray-950">
      <Navbar />
      <Leftbar />
      <Rightbar />
      <MobileMenue/>
      <main className="md:pt-20 pt-[3.4rem] max-w-[1000px] m-auto h-screen flex justify-center items-start relative">
        <div className='absolute top-[50%] right-0 flex flex-col gap-2'>
          <button
            id='upbutton'
            className='text-black dark:text-white bg-gray-100 dark:bg-gray-800 border border-gray-400 dark:border-gray-600 hover:bg-gray-300 dark:hover:bg-gray-700 h-10 w-10 rounded-full'
            onClick={() => handleScroll('up')}
          >
            <i className="ri-arrow-up-line"></i>
          </button>
          <button
            id='downbutton'
            className='text-black dark:text-white bg-gray-100 dark:bg-gray-800 border border-gray-400 dark:border-gray-600 hover:bg-gray-300 dark:hover:bg-gray-700 h-10 w-10 rounded-full'
            onClick={() => handleScroll('down')}
          >
            <i className="ri-arrow-down-line"></i>
          </button>
        </div>

        <div
          id='videoContainer'
          ref={videoContainerRef}
          className='flex flex-col gap-5 h-[89vh] overflow-y-scroll w-full items-center scrollHide'
          style={{ scrollSnapType: 'y mandatory' }} // Enable scroll snapping
        >
          {videoData.map((video, index) => (
            <div
              key={index}
              className={`md:w-[24.4vw] w-[100%] h-[84vh] min-h-[84vh] object-cover relative`}
              style={{ scrollSnapAlign: 'start' }} // Ensure each video is the snap point
            >
              <div className='absolute md:bottom-2 bottom-10 md:-right-12 right-3 z-10'>
                <div className='flex flex-col gap-3'>
                  <div className='flex flex-col items-center relative'>
                    <button className='border border-gray-400 rounded-full'>
                      <img src={video.profileImage} alt="Profile" className='h-9 w-9 rounded-full object-cover'/>
                    </button>
                    <div className='absolute top-[1.3rem] right-0 bg-white rounded-full w-4 h-4 flex items-center justify-center text-red-600'>
                      <i className="ri-add-circle-fill"></i>
                    </div>
                    <div className='text-black dark:text-white text-xs'>Follow</div>
                  </div>
                  <div className='flex flex-col items-center'>
                    <button className='text-gray-950 text-lg dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border px-3 h-9 w-9 rounded-full flex items-center justify-center gap-1 hover:bg-gray-300 dark:hover:bg-gray-700'>
                      <i className="ri-heart-2-fill"></i>
                    </button>
                    <div className='text-black dark:text-white text-xs'>{video.likes}</div>
                  </div>
                  <Link to={'/video'} className='flex flex-col items-center'>
                    <button className='text-gray-950 text-lg dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border px-3 h-9 w-9 rounded-full flex items-center justify-center gap-1 hover:bg-gray-300 dark:hover:bg-gray-700'>
                      <i className="ri-chat-3-fill"></i>
                    </button>
                    <div className='text-black dark:text-white text-xs'>{video.comments}</div>
                  </Link>
                  <div className='flex flex-col items-center'>
                    <button className='text-gray-950 text-lg dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border px-3 h-9 w-9 rounded-full flex items-center justify-center gap-1 hover:bg-gray-300 dark:hover:bg-gray-700'>
                      <i className="ri-share-forward-fill"></i>
                    </button>
                    <div className='text-black dark:text-white text-xs'>Share</div>
                  </div>
                </div>
              </div>
              <div className='md:w-[24.4vw] w-full h-[84vh] min-h-[84vh] object-cover overflow-hidden rounded-lg relative'>
                <div className='absolute top-0 z-10'>
                  <div className='p-3 flex items-center gap-2'>
                    <button
                      className='play-pause text-white bg-gray-800 bg-opacity-70 w-9 h-9 rounded-full'
                      onClick={() => handlePlayPause(index)}
                    >
                      <i className={videoStates[index].playing ? "ri-pause-fill" : "ri-play-fill"}></i>
                    </button>
                    <button
                      className='mute-unmute text-white bg-gray-800 bg-opacity-70 w-9 h-9 rounded-full'
                      onClick={() => handleMuteUnmute(index)}
                    >
                      <i className={videoStates[index].muted ? "ri-volume-mute-fill" : "ri-volume-up-fill"}></i>
                    </button>
                  </div>
                </div>
                <div className='absolute bottom-0 z-10 bg-gradient-to-t from-black to-[#00000000] w-full'>
                  <div className='p-3'>
                    <p className='UserName text-white text-sm font-semibold mb-1'>
                      <span>@</span>{video.username}
                      <span className='text-[10px] font-normal text-gray-300'> | {video.realName}</span>
                    </p>
                    <p className='videoTitle text-xs text-gray-200'>{video.videoTitle}</p>
                    <p className='originalSong text-white text-xs'>
                      <span className='font-semibold'>
                        <i className="ri-music-fill"></i>Original Sound -
                      </span> {video.originalSound}
                    </p>
                  </div>
                </div>
                <video
                  src={video.src}
                  loop
                  className="w-full h-full object-contain bg-black"
                  muted={videoStates[index].muted}
                ></video>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Following;
