import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Home";
import Video from "./Video";
import Following from "./Following";
import Explore from "./Explore";
import Profile from "./Profile";

function App() {


  return (
    <div>
      <BrowserRouter>
    <Routes>  
      <Route path="/" element={<Home/>} />
      <Route path="/video" element={<Video/>} />
      <Route path="/following" element={<Following/>} />
      <Route path="/explore" element={<Explore/>} />
      <Route path="/profile" element={<Profile/>} />
    </Routes>
    </BrowserRouter>
      
    </div>
  );
}

export default App;