import React, { useState, useRef } from 'react';
import Navbar from './components/Navbar';
import Leftbar from './components/Leftbar';
import Rightbar from './components/Rightbar';
import Video1 from './videos/video1.mp4'
import Video2 from './videos/video2.mp4'
import Video3 from './videos/video3.mp4'
import ProfilePic from "./images/profile.jpeg"
import MobileMenue from './components/MobileMenue';

const videos = [
    { src: Video1, title: 'Video Title 1 watch complete video now' },
    { src: Video2, title: 'Video Title 2 watch complete video now' },
    { src: Video3, title: 'Video Title 3 watch complete video now' },
  ];
  


const Profile = () => {
  // State to keep track of muted status
  const [muted, setMuted] = useState(true);
  const videoRefs = useRef([]);

  const handleMouseEnter = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
    }
  };

  const handleMouseLeave = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
    }
  };

  const handleMuteToggle = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].muted = !videoRefs.current[index].muted;
      setMuted(videoRefs.current[index].muted);
    }
  };


  return (
    <div className="bg-gray-50 dark:bg-gray-950">
      <Navbar />
      <Leftbar />
      <Rightbar />
      <MobileMenue/>
      <main className="pt-20 px-4 max-w-[1030px] m-auto overflow-x-hidden">
        <div className='border-b dark:border-slate-400 border-slate-800 mb-5'>
            <div className='flex items-center gap-2'>
                <div><img src={ProfilePic} alt="" className='w-16 h-16 object-cover rounded-full' /></div>
                <div>
                    <h1 className='dark:text-white font-bold text-xl'>Aftab Cheema</h1>
                    <p className='dark:text-gray-400'>@aftab6595</p>
                </div>
            </div>
            <div className='mt-4 flex items-center gap-4'>
                <button className='dark:text-white hover:underline'><span className='font-bold'>102</span> Following</button>
                <button className='dark:text-white hover:underline'><span className='font-bold'>1039</span> Followers</button>
                <button className='dark:text-white hover:underline'><span className='font-bold'>95052</span> Likes</button>
            </div>
            <div className='dark:text-gray-300 text-sm mt-4'>
            Click on the link and please follow and support my page 🥰🙏🏻
            </div>

            <div className='mt-4 flex items-center gap-4'>
            <button className='font-semibold dark:text-white px-4 text-lg border-b-2 dark:border-gray-50 border-gray-800'>Videos</button>
            <button className='font-semibold dark:text-white px-4 text-lg hover:border-b-2 dark:border-gray-50 border-gray-800'>Liked</button>
            </div>
        </div>
        <div className='grid md:grid-cols-6 grid-cols-3 gap-3 min-h-[90vh]'>
        {videos.map((video, index) => (
          <div
            key={index}
            className="video-Container relative group"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <button
              className="mute-unmute text-white bg-gray-800 bg-opacity-70 w-9 h-9 rounded-full absolute top-2 left-2 hidden group-hover:block z-10"
              onClick={() => handleMuteToggle(index)}
            >
              <i className={muted ? 'ri-volume-mute-fill' : 'ri-volume-up-fill'}></i>
            </button>
            <video
              src={video.src}
              loop
              muted={muted} // Initial mute state
              ref={(el) => (videoRefs.current[index] = el)} // Assign refs dynamically
              className="object-contain bg-black rounded-lg w-full"
            ></video>
            <p className="dark:text-white text-xs mt-1">{video.title}</p>
          </div>
        ))}
        </div>
      </main>
    </div>
  );
};

export default Profile;
