import React, { useState, useRef } from 'react';
import Navbar from './components/Navbar';
import Leftbar from './components/Leftbar';
import Rightbar from './components/Rightbar';
import Video1 from './videos/video1.mp4'
import Video2 from './videos/video2.mp4'
import Video3 from './videos/video3.mp4'
import MobileMenue from './components/MobileMenue';

const videos = [
    { src: Video1, title: 'Video Title 1 watch complete video now' },
    { src: Video2, title: 'Video Title 2 watch complete video now' },
    { src: Video3, title: 'Video Title 3 watch complete video now' },
    { src: Video1, title: 'Video Title 1 watch complete video now' },
    { src: Video2, title: 'Video Title 2 watch complete video now' },
    { src: Video3, title: 'Video Title 3 watch complete video now' },
    { src: Video1, title: 'Video Title 1 watch complete video now' },
    { src: Video2, title: 'Video Title 2 watch complete video now' },
    { src: Video3, title: 'Video Title 3 watch complete video now' },
    { src: Video1, title: 'Video Title 1 watch complete video now' },
    { src: Video2, title: 'Video Title 2 watch complete video now' },
    { src: Video3, title: 'Video Title 3 watch complete video now' },
    { src: Video1, title: 'Video Title 1 watch complete video now' },
    { src: Video2, title: 'Video Title 2 watch complete video now' },
    { src: Video3, title: 'Video Title 3 watch complete video now' },
  ];
  


const Explore = () => {
  // State to keep track of muted status
  const [muted, setMuted] = useState(true);
  const videoRefs = useRef([]);

  const handleMouseEnter = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
    }
  };

  const handleMouseLeave = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
    }
  };

  const handleMuteToggle = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].muted = !videoRefs.current[index].muted;
      setMuted(videoRefs.current[index].muted);
    }
  };


  return (
    <div className="bg-gray-50 dark:bg-gray-950">
      <Navbar />
      <Leftbar />
      <Rightbar />
      <MobileMenue/>
      <main className="pt-20 px-4 max-w-[1030px] m-auto">

        <div className='grid md:grid-cols-6 grid-cols-3 gap-3'>
        {videos.map((video, index) => (
          <div
            key={index}
            className="video-Container relative group"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <button
              className="mute-unmute text-white bg-gray-800 bg-opacity-70 w-9 h-9 rounded-full absolute top-2 left-2 hidden group-hover:block z-10"
              onClick={() => handleMuteToggle(index)}
            >
              <i className={muted ? 'ri-volume-mute-fill' : 'ri-volume-up-fill'}></i>
            </button>
            <video
              src={video.src}
              loop
              muted={muted} // Initial mute state
              ref={(el) => (videoRefs.current[index] = el)} // Assign refs dynamically
              className="object-contain bg-black rounded-lg w-full"
            ></video>
            <p className="dark:text-white text-xs mt-1">{video.title}</p>
          </div>
        ))}
        </div>
      </main>
    </div>
  );
};

export default Explore;
