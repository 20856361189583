import React from 'react'
import NoProfile from '../images/no-profile.jpg'
import { Link, useLocation } from 'react-router-dom';


const Leftbar = () => {

    const location = useLocation();

    const getLinkClasses = (path) => {
      return location.pathname === path
        ? 'hover:bg-gray-200 dark:hover:bg-gray-800 bg-gray-200 dark:bg-gray-800 font-semibold text-orange-600 px-3 py-1.5 rounded-md cursor-pointer flex items-center gap-2'
        : 'hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer flex items-center gap-2';
    };


  return (
    <div className='h-screen fixed dark:bg-gray-950 bg-gray-50 border-r border-gray-200 dark:border-gray-700 top-0 left-0 w-[15rem] z-0 pt-16 hidden md:block'>
     <div className='p-4'>
        <ul className='text-black dark:text-white flex flex-col gap-2'>
      <Link to='/' className={getLinkClasses('/')}>
        <i className='ri-home-3-fill text-lg'></i> <span>For You</span>
      </Link>
      <Link to='/explore' className={getLinkClasses('/explore')}>
        <i className='ri-compass-line text-lg'></i> <span>Explore</span>
      </Link>
      <Link to='/following' className={getLinkClasses('/following')}>
        <i className='ri-user-follow-line text-lg'></i> <span>Following</span>
      </Link>
      <Link to='/profile' className={getLinkClasses('/profile')}>
        <i className='ri-user-smile-line text-lg'></i> <span>Profile</span>
      </Link>
    </ul>
        <hr className='border-none bg-gray-200 dark:bg-gray-800 h-[1px] my-2'/>
        <div>
            <p className='text-black dark:text-gray-200 text-sm font-semibold'>Your Following Accounts</p>
            <p className='text-black dark:text-gray-400 text-xs py-1'>Accounts you follow will appear here</p>
            <ul className='flex flex-col gap-2 mt-1'>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Sajin Tamang</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@Itssaj07</p>
                    </div>
                </li>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Anmol Shashankar</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@anmolsha</p>
                    </div>
                </li>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Aalok Zha</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@aalok</p>
                    </div>
                </li>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Kaushal Gaming</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@KaushalG</p>
                    </div>
                </li>
            </ul>
        </div>
        <hr className='border-none bg-gray-200 dark:bg-gray-800 h-[1px] my-2'/>
        <div>
            <p className='text-black dark:text-gray-200 text-sm font-semibold'>Company</p>
            <p className='text-black dark:text-gray-400 text-xs py-1'>NePlay Transparency </p>
            <ul className='flex flex-col gap-2 mt-1'>
                <li className='text-black dark:text-gray-300 text-xs cursor-pointer hover:dark:text-white hover:font-semibold'>About</li>
                <li className='text-black dark:text-gray-300 text-xs cursor-pointer hover:dark:text-white hover:font-semibold'>Contact</li>
                <li className='text-black dark:text-gray-300 text-xs cursor-pointer hover:dark:text-white hover:font-semibold'>Privacy Policy</li>
                <li className='text-black dark:text-gray-300 text-xs cursor-pointer hover:dark:text-white hover:font-semibold'>Terms and Conditions</li>
            </ul>
        <hr className='border-none bg-gray-200 dark:bg-gray-800 h-[1px] my-2'/>
            <p className='text-black dark:text-gray-400 text-[10px] mt-3'>Copyright <i className="ri-copyright-line"></i> 2024 Neplay</p>
        </div>
     </div>
    </div>
  )
}

export default Leftbar
