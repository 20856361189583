import React, { useState, useRef } from 'react';
import Navbar from './components/Navbar'
import NoProfile from './images/no-profile.jpg'
import Video1 from './videos/video1.mp4'
import { Link } from 'react-router-dom';

const Video = () => {

    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const videoRef = useRef(null);
  
    const handlePlayPause = () => {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    };
  
    const handleMuteUnmute = () => {
      if (videoRef.current) {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
      }
    };

  return (
    <div className="bg-gray-50 dark:bg-gray-950">
        <Navbar/>
      <div className='grid md:grid-cols-5 grid-cols-1 h-screen'>
        <div className=' md:col-span-3 flex items-center justify-center pt-[4.3rem] relative'>
            <Link to={'/'} className=' absolute top-[4.5rem] left-8 text-2xl dark:text-slate-200 dark:bg-slate-700 bg-slate-300 bg-opacity-50 border dark:border-slate-500 border-slate-300 h-10 w-10 rounded-full flex items-center justify-center'><i class="ri-arrow-left-line"></i></Link>
            <div className='md:w-[26.4vw] w-[50%] md:h-[90vh] md:min-h-[90vh] object-cover overflow-hidden rounded-lg relative'>
            <div className='absolute top-0 z-10'>
                  <div className='p-3 flex items-center gap-2'>
                    <button
                      className='play-pause text-white bg-gray-800 bg-opacity-70 w-9 h-9 rounded-full'
                      onClick={handlePlayPause}
                    >
                      <i className={isPlaying ? 'ri-pause-fill' : 'ri-play-fill'}></i>
                    </button>
                    <button
                      className='mute-unmute text-white bg-gray-800 bg-opacity-70 w-9 h-9 rounded-full'
                      onClick={handleMuteUnmute}
                    >
                      <i className={isMuted ? 'ri-volume-mute-fill' : 'ri-volume-up-fill'}></i>
                    </button>
                  </div>
                </div>
                <video
                ref={videoRef}
                  src={Video1}
                  loop
                  className="w-full h-full object-contain bg-black"
                  autoPlay
                ></video>
            </div>
        </div>
        <div className=' md:col-span-2 dark:bg-slate-900 border dark:border-slate-800 border-slate-200 bg-slate-100 p-4 pt-20 relative h-screen overflow-y-scroll overflow-x-hidden'>
        <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
        <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
        <div className=' dark:text-white'>
            <h1 className='text-sm font-bold'>John Doe</h1>
            <p className='text-xs text-gray-500'>@johndoe</p>
        </div>
        </div>
        <div>
            <button className='bg-gradient-to-br to-orange-600 from-red-600 text-white font-semibold py-1 px-4 rounded-md hover:to-red-600 hover:from-orange-600 hover:scale-105 duration-150'>Follow</button>
        </div>
        </div>
        <div>
            <p className='text-xs mt-5 text-slate-700 dark:text-slate-300'>A YouTuber [ ▸ ] Traveller & Engineering student 🧑‍💻Navigating academia's challenges !! Join my journey...📈 Insta @aalok_zha...</p>
            <button className='text-sm font-semibold dark:text-white'>more</button>

            <p className='originalSong dark:text-white text-black text-sm mt-2'>
                      <span className='font-semibold'>
                        <i className="ri-music-fill"></i>Original Sound -
                      </span> New Song 2024 Music
                    </p>

                    <div className='flex items-center justify-between mt-2'>
                        <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-1.5'>
                    <button className='text-gray-950 text-lg dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border px-3 h-7 w-7 rounded-full flex items-center justify-center gap-1 hover:bg-gray-300 dark:hover:bg-gray-700'>
                      <i className="ri-heart-2-fill"></i>
                    </button>
                    <div className='text-black dark:text-white text-xs'>12</div>
                  </div>
                  <div className='flex items-center gap-1.5'>
                    <button className='text-gray-950 text-lg dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border px-3 h-7 w-7 rounded-full flex items-center justify-center gap-1 hover:bg-gray-300 dark:hover:bg-gray-700'>
                      <i className="ri-chat-3-fill"></i>
                    </button>
                    <div className='text-black dark:text-white text-xs'>2</div>
                  </div>
                        </div>
                        <div className='flex items-center gap-1'>
                            <button className='text-base text-blue-500 bg-blue-500 bg-opacity-20 w-7 h-7 rounded-full border border-blue-500 flex items-center justify-center'><i className="ri-facebook-circle-fill"></i></button>
                            <button className='text-base text-sky-500 bg-sky-500 bg-opacity-20 w-7 h-7 rounded-full border border-sky-500 flex items-center justify-center'><i className="ri-twitter-fill"></i></button>
                            <button className='text-base text-orange-500 bg-orange-500 bg-opacity-20 w-7 h-7 rounded-full border border-orange-500 flex items-center justify-center'><i className="ri-instagram-fill"></i></button>
                        </div>
                    </div>
                    <div className='mt-3 flex gap-1 rounded dark:bg-slate-500 bg-slate-200'>
                            <input className='w-full p-2 bg-transparent dark:text-white text-sm' type="text" readOnly value={'https://www.neplay.app/video/4902'} />
                            <button className='dark:text-white text-sm w-36 dark:bg-slate-600 bg-slate-300 rounded-r hover:font-semibold'><i className="ri-link"></i> Copy Link</button>
                        </div>
                        
                        <div className='absolute w-full bottom-0 border-t border-slate-500 right-0 p-4 dark:bg-slate-800 bg-slate-50'>
                        <div className='flex gap-1 rounded dark:bg-slate-500 bg-slate-200'>
                            <input className='w-full p-2 bg-transparent dark:text-white text-sm' type="text" readOnly value={'https://www.neplay.app/video/4902'} />
                            <button className='dark:text-white text-sm w-36 dark:bg-slate-600 bg-slate-300 rounded-r hover:font-semibold'><i className="ri-link"></i> Copy Link</button>
                        </div>
                        </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Video
