import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const MobileMenue = () => {
    const location = useLocation();

    const getLinkClasses = (path) => {
      return location.pathname === path
        ? 'hover:bg-gray-200 dark:hover:bg-gray-800 bg-gray-200 dark:bg-gray-800 font-semibold text-orange-600 px-3 py-1 rounded-md cursor-pointer flex flex-col items-center'
        : 'hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer flex flex-col items-center';
    };

    
  return (
    <div className=' fixed bottom-0 left-0 md:hidden block z-20 dark:bg-slate-950 bg-slate-100 w-full p-2'>
      <ul className='text-black dark:text-white flex justify-between'>
      <Link to='/' className={getLinkClasses('/')}>
        <i className='ri-home-3-fill text-3xl'></i> <span className='text-xs'>For You</span>
      </Link>
      <Link to='/explore' className={getLinkClasses('/explore')}>
        <i className='ri-compass-line text-3xl'></i> <span className='text-xs'>Explore</span>
      </Link>
      <Link to='/following' className={getLinkClasses('/following')}>
        <i className='ri-user-follow-line text-3xl'></i> <span className='text-xs'>Following</span>
      </Link>
      <Link to='/profile' className={getLinkClasses('/profile')}>
        <i className='ri-user-smile-line text-3xl'></i> <span className='text-xs'>Profile</span>
      </Link>
    </ul>
    </div>
  )
}

export default MobileMenue
