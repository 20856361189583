import React from 'react'
import { useState, useEffect } from "react";
import neplayIcon from "../images/neplayIcon.png"
import Profile from "../images/profile.jpeg"
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [theme, setTheme] = useState(null);

    useEffect(() => {
      if(window.matchMedia('(prefers-color-scheme: dark)').matches){
        setTheme('dark');
      }
      else {
        setTheme('light');
      }
    }, [])
  
    useEffect(() => {
      if (theme === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }, [theme]);
  
    const handleThemeSwitch = () => {
      setTheme(theme === "dark" ? "light" : "dark");
    };

  return (
    <div className=' py-3 md:px-10 px-3 flex justify-between items-center border-b border-gray-200 dark:border-gray-700 fixed w-full z-50 dark:bg-gray-950 bg-gray-50'>
      <div>
      <Link to={'/'} className='flex items-center gap-1'>
        <img src={neplayIcon} alt="" className='h-6' />
        <div className='text-gray-950 dark:text-white font-semibold text-xl'>NePlay</div>
      </Link>
      </div>
      <div>
        <div className='md:flex hidden bg-gray-100 dark:bg-gray-800 border-gray-400 dark:border-gray-600 border h-9 items-center gap-2 pl-4 rounded-full w-96 justify-between'>
        <input type="search" placeholder='Search users here' className='bg-transparent text-sm focus:outline-none text-black dark:text-white w-full' />
        <button className='dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 h-[2.10rem] w-14 rounded-full border-gray-400 dark:border-gray-600  block'>
        <i className="ri-search-2-line text-black dark:text-white"></i>
        </button>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        
      <button className='text-gray-950 dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border px-3 h-8 rounded-full flex items-center justify-center gap-1 hover:bg-gray-300 dark:hover:bg-gray-700 '>
      <i className="ri-video-upload-fill"></i> <span className='text-sm font-semibold'>Upload</span> 
      </button>
      <button className="text-gray-950 dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-gray-700 ">
      <i className="ri-question-mark"></i>
      </button>
      <button className="text-gray-950 dark:text-gray-50 dark:bg-gray-800 bg-gray-100 border-gray-400 dark:border-gray-600 border w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-gray-700 " onClick={handleThemeSwitch}>
      <i className={`${theme === 'dark'? 'ri-sun-fill' : 'ri-moon-clear-fill'}`}></i>
      </button>
      
      <Link to={'/profile'} className='border border-gray-400 rounded-full'>
        <img src={Profile} alt="" className='h-8 w-8 rounded-full object-cover'/>
      </Link>
      
      </div>
    </div>
  )
}

export default Navbar
