import React from 'react'
import NoProfile from '../images/no-profile.jpg'

const Rightbar = () => {
  return (
    <div className='h-screen fixed dark:bg-gray-950 bg-gray-50 top-0 right-0 w-[15rem] z-0 border-l border-gray-200 dark:border-gray-700 pt-20 px-4 hidden md:block'>
        <div className='bg-gray-800 dark:bg-gray-200 rounded-md p-3'>
        <p className='text-orange-600 dark:text-orange-600 text-sm font-semibold'>Get NePlay App</p>
        <p className='text-white dark:text-black text-xs py-1'>Download And Enjoy On Your Mobile Devices</p>
        <ul className=' text-white flex flex-col gap-2 mt-2'>
            <li className=' bg-gradient-to-br to-orange-600 from-red-600 px-3 py-2 rounded-md cursor-pointer flex items-center gap-2 text-xs justify-center hover:scale-105'><i className="ri-google-play-fill"></i> <span>Android NePlay App</span></li>
            <li className=' bg-gradient-to-br to-orange-600 from-red-600 px-3 py-2 rounded-md cursor-pointer flex items-center gap-2 text-xs justify-center hover:scale-105'><i className="ri-apple-fill"></i> <span>IOS NePlay App</span></li>
        </ul>
        </div>
        <hr className='border-none bg-gray-200 dark:bg-gray-800 h-[1px] my-2'/>
      <div>
            <p className='text-black dark:text-gray-200 text-sm font-semibold'>Suggested Accounts</p>
            <p className='text-black dark:text-gray-400 text-xs py-1'>Explore and Follow More Peoples</p>
            <ul className='flex flex-col gap-2 mt-1'>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Sajin Tamang</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@Itssaj07</p>
                    </div>
                </li>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Anmol Shashankar</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@anmolsha</p>
                    </div>
                </li>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Aalok Zha</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@aalok</p>
                    </div>
                </li>
                <li className='flex items-center gap-1.5 hover:bg-gray-200 dark:hover:bg-gray-800 px-3 py-1.5 rounded-md cursor-pointer'>
                    <img src={NoProfile} alt="" className='w-8 h-8 object-cover rounded-full' />
                    <div>
                    <p className='text-black dark:text-gray-100 text-sm font-semibold'>Kaushal Gaming</p>
                    <p className='text-black dark:text-gray-400 text-xs'>@KaushalG</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Rightbar
